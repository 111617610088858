import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCztIyA8jxn3ktl8tDEy27FsCXz_tR5jzo",
  authDomain: "cookyswap.firebaseapp.com",
  projectId: "cookyswap",
  storageBucket: "cookyswap.appspot.com",
  messagingSenderId: "192931515553",
  appId: "1:192931515553:web:016d66970e89273d9374e0",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

let db = firebase.firestore();

export { firebase, db };

// const auth = getAuth();
// onAuthStateChanged(auth, (user) => {
//   if (user) {
//     // User is signed in, see docs for a list of available properties
//     // https://firebase.google.com/docs/reference/js/firebase.User
//     const uid = user.uid;
//     // ...
//   } else {
//     // User is signed out
//     // ...
//   }
// });

// export { app, auth };
// export default firebase;
