//authStore.js
import { defineStore } from "pinia";

export const useAuthStore = defineStore("AuthStore", {
  state: () => {
    return {
      isAuthenticated: false,
      isAdmin: false,
      user: {},
    };
  },
  actions: {
    // setUser(userData: User) {
    //     const copyOfData = { ...userData }
    //     // or...
    //     const copyOfData = extend(true /* deep */, {}, userData)
    //     LocalStorage.set('count', count)
    //     this.user = copyOfData
    //   },
  },
  actions: {
    // addTodo(todo) {
    //     this.todos.push(todo);
    // },
    // removeUser(index) {
    //     this.todos.splice(index, 1)
    // }
  },
});
