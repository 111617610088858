import { useAuthStore } from "stores/authStore";
import { route } from "quasar/wrappers";
import {
  createRouter,
  createMemoryHistory,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import { firebase } from "src/boot/firebase";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import routes from "./routes";

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function ({ store, ssrContext }) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === "history"
    ? createWebHistory
    : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === "ssr" ? void 0 : process.env.VUE_ROUTER_BASE
    ),
  });
  Router.beforeEach(async (to, from, next) => {
    const currentUser = await firebase.getCurrentUser();
    if (currentUser) {
      await currentUser.getIdTokenResult().then((idTokenResult) => {
        const store = useAuthStore();
        store.isAdmin = idTokenResult.claims.admin;
        store.user = idTokenResult.claims;
      });
    }
    const store = useAuthStore();
    const isAdmin = store.isAdmin;
    const auth = to.meta.requiresAuth;
    const requiresAdmin = to.meta.requiresAdmin;
    const alreadyAuth = to.meta.alreadyAuth;

    if (auth) {
      if (requiresAdmin) {
        if (isAdmin === true) {
          next();
        } else {
          next("");
          return;
        }
      } else {
        if (!currentUser) {
          next("login");
          return;
        } else {
          next();
        }
      }
    }

    // handle login redirect as already logged in
    else if (alreadyAuth && (await firebase.getCurrentUser())) {
      next("");
      return;
    } else {
      next();
      return;
    }
  });
  return Router;
});
